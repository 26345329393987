<template>
    <div class="lastCours container">
        <h2>Les derniers topics</h2>
        <div class="row">
            <div class="lastCours__container">
                <div class="" v-for="post in sclicedTab" v-bind:key="post.id">
                    <router-link :to="'/cours/' + post.title.rendered.toLowerCase()">
                        <div class="lastCours__container__card">
                            <p class="icone">📖</p>
                            <div class="lastCours__container__card__titre">
                                <h3 v-html="post.title.rendered"></h3>
                            </div>
                            <div class="call-to-action">
                                <p>Accéder à ce topic</p>
                            </div>
                        </div>
                    </router-link>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            posts: [],
        };
    },
    methods: {
        async getData() {
            try {
                let response = await
                    fetch("https://digitopics.fr/admin/wp-json/wp/v2/posts");
                this.posts = await response.json();
            } catch (error) {
                console.log(error);
            }
        },
    },
    computed: { 
        sclicedTab: function () { 
            return this.posts.slice(0, 8);
        } 
    },
    created() {
        this.getData();
    },
};
</script>

<style scoped lang="scss">
.lastCours {
    h2 {
        margin-bottom: 20px;
        font-size: 2.8rem !important;
        text-transform: uppercase;
        font-weight: 200;
        text-align: left;

        @media screen and (max-width:992px) {
            font-size: 2rem !important;
            text-transform: uppercase;
        }
    }
    &__container {
        display:flex;
        overflow-x: scroll;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        &__card {
            min-width: 320px;
            margin-right: 15px;
            padding: 12px;
        }

        h3 {
            text-transform: uppercase;
            font-weight: 400!important;
        }

        p {
            margin: 0px !important;
        }
        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }
    
}
</style>