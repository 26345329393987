<template>
  <div class="hello">
    <div class="entete container">
      <div class="titles">
        <h1>{{ msg }}</h1>
        <p>Les pense-bêtes de la com</p>
      </div>
       <div class="cta"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-mouse-fill" viewBox="0 0 16 16">
          <path d="M3 5a5 5 0 0 1 10 0v6a5 5 0 0 1-10 0V5zm5.5-1.5a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0v-2z" />
      </svg></div>
    </div>
    
    <div class="container corps corps1">
      <p>Cette application est un (modeste) recueil de topics et d'astuces acquis durant l'ensemble de mon parcours
        scolaire et professionnel. &#128187;&#128526;&#128171;</p>
    </div>
    <LastCours />
    <LastThemes />

  </div>
</template>

<script>
import LastThemes from '@/components/LastCours.vue'
import LastCours from '@/components/LastThemes.vue'
export default {
  name: 'HomePresentation',
  props: {
    msg: String
  },
  components: {
    LastCours,
    LastThemes,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hello {
  color: #121b29;
  margin-bottom: 20px
}

.entete {
  position: relative;
  margin-bottom: 30px;
  margin-top: 10px;
  text-align: left;
  .cta {
    background: #EA334B;
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    animation: 3s linear 1s infinite alternate slide;
    margin-top: 15px;


    @keyframes slide {
      0% {
        transform: translateY(0px);

      }

      50% {
        transform: translateY(10px);
      }

      100% {
        transform: translateY(0px);
      }
    }

    svg {
      height: 25px;
      width: 25px;

      path {
        fill: #fff;
        ;
      }
    }
  }


  .titles {

    width: 100%;
    text-align: left;

    p {
      font-size: 1.4rem;
      margin: 0px;
      
      color: #000;
      line-height: 2rem;
    }

    h1 {
      color: #000;
      padding: 0px;
    }
  }
}

.corps {
  margin-top: 30px;
}
.corps1 {
  font-weight: 500;
}
.corps1 p {
  text-align: left;
  font-size: 1.1rem;
}

.call-to-action p {
  text-align: center;
}

h1 {
  font-size: 5rem !important;
  text-transform: uppercase;
  margin: 0;

  @media screen and (max-width:992px) {
    font-size: 3rem !important;
  }
}

h2 {
  margin-bottom: 20px;
  font-size: 3rem !important;
  text-transform: uppercase;
  font-weight: 200;

  @media screen and (max-width:992px) {
    font-size: 2rem !important;
    text-transform: uppercase;
  }
}

h3 {
  text-transform: uppercase;
}

p {
  text-align: left;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #EA334B;
}
</style>
