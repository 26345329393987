<template>
    <div class="sousCatList container  mb-3">
            <div class="back">
                <button @click="goBack">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                    </svg>
                </button>
            </div>
            <div v-if="error">
                {{ error }}
            </div>
            <div class="sousCatList__container" v-else>
                <div class="row" >
                    
                    <div class="col-lg-4" v-for="post in filterParam" :key="post.id">
                        <router-link :to="'/cours/' + post.title.rendered.toLowerCase()">
                            <div class="sousCatList__container__card">
                                <p class="icone">📖</p>
                                <div class="sousCatList__container__card__titre">
                                    <h3 v-html="post.title.rendered"></h3>
                                </div>
                                <div class="call-to-action">
                                    <p>Accéder à ce topic</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    
                </div>
            </div>
    </div>
</template >

<script>
export default {
    data() {
        return {
            posts: [],
            sCatParam: this.$route.params.name,
        };
    },
    methods: {
        async getData() {
            try {
                let response = await
                    fetch("https://digitopics.fr/admin/wp-json/wp/v2/posts");
                this.posts = await response.json();
            } catch (error) {
                console.log(error);
            }
        },
        goBack() {
            this.$router.go(-1)
        },
    },
    computed: { 
        filterParam: function () { 
            return this.posts.filter((t => t.acf.sous_categorie.toLowerCase() == this.sCatParam)) 
        }
    },
    created() {
        this.getData();
    },
};
</script>

<style lang="scss">
    .sousCatList {
        &__container {
            margin-top:30px;
        }
    }
</style>