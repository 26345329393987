<template>
    <div class="about">
        <CoursListProgra category='Programmation'></CoursListProgra>
    </div>
</template>


<script>
import CoursListProgra from '@/components/CoursListProgra.vue'

export default {
    name: 'DesignCours',
    props: [
    'category', 
    ],
    components: {
        CoursListProgra,
    },
}
</script>