<template>
    <CoursMain></CoursMain>
</template>


<script>
import CoursMain from '@/components/CoursMain.vue'

export default {
    name: 'DetailsCours',
    components: {
        CoursMain,
    },
}
</script>