<template>
    <div class="about">
        <CoursListMarket category='Marketing'></CoursListMarket>
    </div>
</template>


<script>
import CoursListMarket from '@/components/CoursListMarket.vue'

export default {
    name: 'DesignCours',
    props: [
    'category', 
    ],
    components: {
        CoursListMarket,
    },
}
</script>