<template>
    <div class="coursContainer container">
        <div v-if="error">
            {{ error }}
        </div>
        <div class="row" v-else>
            <h1>Programmation</h1>
            <p class="sous-titre">Les pense-bêtes de la com</p>
            <div class="col-lg-4" v-for="post in filterThemes" :key="post.id">
                <router-link :to="'/sous-categorie/' + post.toLowerCase()">
                    <div class="coursContainer__container__card">
                        <p class="icone">📂</p>
                        <div class="coursContainer__container__card__titre">
                            <h3 v-html="post"></h3>
                        </div>
                        <div class="call-to-action">
                            <p>Accéder à ce thème</p>
                        </div>
                    </div>
                </router-link>
            </div>
           
        </div>
    </div>
</template>

<script>
export default {
    props: ['category'],
    data() {
        return {
            posts: [],
        };
    },
    computed: {
        filterThemes: function(){
            return this.uniqueNames(this.filterThemesByCat(this.posts))
        }
    },
    methods: {
        async getData() {
            try {
                let response = await
                    fetch("https://digitopics.fr/admin/wp-json/wp/v2/posts");
                this.posts = await response.json();
                console.log(this.posts);
            } catch (error) {
                console.log(error);
            }
        },
        filterThemesByCat: function (products) { 
            return products.filter((t => t.acf.categorie == this.category))
        },
        uniqueNames: function(products) {
            var Remove_duplicate_Value = [];
            for(var i =0; i < products.length; i++) {
                if(Remove_duplicate_Value.indexOf(products[i].acf.sous_categorie) === -1) {
                    Remove_duplicate_Value.push(products[i].acf.sous_categorie)
                }
            }
        console.log(Remove_duplicate_Value);
        return Remove_duplicate_Value;
        }
    },
    created() {
        this.getData();
    },
};
</script>

<style lang="scss">
.coursContainer,
.lastCours, .sousCatList, .lastThemes {
    h1 {
        font-size: 2.6rem!important;
        text-align: left;
        margin-bottom: 0px;
    }
    .sous-titre {
        font-size: 1.4rem;
        color: #000;
        text-align: left;
        margin-bottom: 15px;
    }
    p.icone {
        font-size: 2rem;
        margin: 0px;
        box-shadow: 2px 2px 6px rgba(0,0,0,0.3);
        border-radius: 50px;
        height: 50px;
        width: 50px;
    }
    margin-top: 50px;

    a {
        text-decoration: none;
        color: inherit;
    }

    .col-lg-4 {
        margin-bottom: 15px;
    }
    &__container {
        &__card {
            border: 1.5px solid transparent;
            padding: 12px!important;
            transition: 0.1s linear;
            background: #fff;
            color: #121b29;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            box-shadow: 2px 2px 6px rgba(0,0,0,0.5)!important;

            &__contenu {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 2;
                -webkit-box-orient: vertical;

                p {
                    margin: 0px;
                }
            }

            svg {
                height: 20px;
                width: 20px;
            }

            &:hover {
                border: 1.5px solid #010916;
                transition: 0.1s linear;
                color: #fff;
                background: none;
                .call-to-action {
                    p {
                        text-decoration: underline;
                        margin: 0px;
                        margin-top: 0px !important;
                        text-transform: uppercase;
                        font-size: 0.8rem;
                        font-weight: 600;
                        color: #fff!important;
                    }
                }
            }
        }
    }
    

    .call-to-action {
        p {
            text-decoration: underline;
            margin: 0px;
            margin-top: 0px !important;
            text-transform: uppercase;
            font-size: 0.8rem;
            font-weight: 600;
            color: #010916!important;
        }
    }

    @media screen and (max-width:992px) {
        margin-top: 20px;
    }
}

h3 {
    text-transform: uppercase;
    font-weight: 400 !important;
    margin: 10px 0px !important;
    font-size: 1.2rem!important;
}
</style>