<template>
  <div class="about">
    <CoursListDesign category='Design'></CoursListDesign>
  </div>
</template>


<script>
import CoursListDesign from '@/components/CoursListDesign.vue'

export default {
  name: 'DesignCours',
  props: [
    'category', 
  ],
  components: {
    CoursListDesign,
  },
}
</script>