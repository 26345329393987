<template>
  <div class="home">
    
    <HomePresentation msg="Digitopics" />
    
  </div>
</template>

<script>
// @ is an alias to /src
import HomePresentation from '@/components/HomePresentation.vue'

export default {
  name: 'LeConcept',
  components: {
    HomePresentation,
  }
}
</script>

<style scoped lang="scss">
.home {
  @media screen and (max-width:992px) {}
}
</style>