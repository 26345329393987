<template>
    <SousCategorieList></SousCategorieList>
</template>


<script>
import SousCategorieList from '@/components/SousCategorieList.vue'

export default {
    name: 'DetailsCours',
    components: {
        SousCategorieList,
    },
}
</script>