<template>
    <div class="detailsCours mb-3">
        <div class="" v-for="post in posts" :key="post.id">
            <div class="ex" v-if="dCoursParam == post.title.rendered.toLowerCase()">
                <div class="enteteMain">
                    <p class="icone">📖</p>
                    <div class="back container">
                        <button @click="goBack">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                        </button>
                    </div>
                    <h1 v-html="post.title.rendered"></h1>
                </div>
                <div v-html="post.content.rendered" class="container cours-content"></div>
            </div>
        </div>

    </div>
</template >
            

<script>
export default {
    data() {
        return {
            posts: [],
            dCoursParam: this.$route.params.name,
        };
    },
    methods: {
        async getData() {
            try {
                let response = await
                    fetch("https://digitopics.fr/admin/wp-json/wp/v2/posts");
                this.posts = await response.json();
            } catch (error) {
                console.log(error);
            }
        },
        goBack() {
            this.$router.go(-1)
        },
    },
    created() {
        this.getData();
    },
};
</script>
            
<style lang="scss">
.enteteMain {
    padding-top: 40px;
    padding-bottom: 10px;
    background: #fff;
    h1 {
        color: black!important;
        font-weight: 400 !important;
    }
    p.icone {
        font-size: 2.5rem;
        margin: auto;
        box-shadow: 2px 2px 6px rgba(0,0,0,0.3);
        border-radius: 50px;
        height: 70px;
        width: 70px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.cours-content {
    margin-top: 30px;
    text-align: left;

    h2 {
        font-size: 1.6rem;
        @media screen and (max-width:992px) {
            font-size: 1.3rem !important;
        }
    }

    h3 {
        font-weight: 200 !important;
        text-transform: uppercase;
        font-size: 1.4rem;
    }

    @media screen and (max-width:992px) {
        padding: 0px 30px !important;
    }
}

h1 {
    color: #fff;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 200 !important;
    font-size: 2rem !important;
    padding: 0px 20px;
    @media screen and (max-width:992px) {
        font-size: 1.5rem !important;
    }
}

.back {
    text-align: left;

    button {
        background: #EA334B;
        border: 0px;
        color: #fff;
        display: flex;
        align-items: center;
        height: 55px;
        width: 55px;
        border-radius: 100px;
        justify-content: center;
        box-shadow: 2px 2px 6px rgba(0,0,0,0.3);

        svg {
            height: 45px;
            width: 45px;
        }

        p {
            margin: 0;
            text-transform: uppercase;
            margin-top: 1px;
        }
    }

}

@media screen and (max-width:992px) {
    .back {
        position: fixed;
        bottom: 135px;

    }
}
</style>
