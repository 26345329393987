<template>
    <div class="lastThemes container">
        <h2>Les derniers thèmes</h2>
        <div class="row">
            <div class="lastThemes__container">
                <div class="" v-for="post in uniqueNames" v-bind:key="post.id">
                     <router-link :to="'/sous-categorie/' + post.toLowerCase()">
                        <div class="lastThemes__container__button">
                            <div class="lastThemes__container__button__titre">
                                <h3 v-html="post"></h3>
                            </div>
                        </div>
                    </router-link>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            posts: [],
        };
    },
    methods: {
        async getData() {
            try {
                let response = await
                    fetch("https://digitopics.fr/admin/wp-json/wp/v2/posts");
                this.posts = await response.json();
            } catch (error) {
                console.log(error);
            }
        },
    },
    computed: { 
        uniqueNames: function() {
            var Remove_duplicate_Value = [];
            for(var i =0; i < this.posts.length; i++) {
                if(Remove_duplicate_Value.indexOf(this.posts[i].acf.sous_categorie) === -1) {
                    Remove_duplicate_Value.push(this.posts[i].acf.sous_categorie)
                }
            }
        console.log(Remove_duplicate_Value);
        return Remove_duplicate_Value.slice(0, 8);
        }
    },
    created() {
        this.getData();
    },
};
</script>

<style scoped lang="scss">
.lastThemes {
    h2 {
        margin-bottom: 20px;
        font-size: 2.8rem !important;
        text-transform: uppercase;
        font-weight: 200;
        text-align: left;

        @media screen and (max-width:992px) {
            font-size: 2rem !important;
            text-transform: uppercase;
        }
    }
    &__container {
        display:flex;
        overflow-x: scroll;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        &__button {
            background: #fff;
            padding: 1px 20px;
            border-radius: 50px;
            margin-right: 10px;
            &:hover {
                h3 {
                    color: #EA334B!important
                }
            }
        }

        h3 {
            text-transform: uppercase;
            font-weight: 400!important;
            width: max-content;
            font-size: 1.2rem;
        }

        p {
            margin: 0px !important;
        }
        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }
    
}
</style>