<template>
  <div class="navbar">
    <div class="container">
      <router-link to="/"><img alt="Vue logo" src="../assets/logo.svg"></router-link>
      <!--<router-link class="mobile" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
          fill="currentColor" class="bi bi-person-heart" viewBox="0 0 16 16">
          <path
            d="M9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276Z" />
        </svg>Profil</router-link>-->
      <nav>
        <router-link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-house-door-fill" viewBox="0 0 16 16">
            <path
              d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z" />
          </svg>Concept</router-link>
        <router-link to="/design"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-palette-fill" viewBox="0 0 16 16">
            <path
              d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07zM8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
          </svg>Design</router-link>
        <router-link to="/programmation"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" class="bi bi-file-earmark-code-fill" viewBox="0 0 16 16">
            <path
              d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.646 7.646a.5.5 0 1 1 .708.708L5.707 10l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 10 8.646 8.354a.5.5 0 1 1 .708-.708z" />
          </svg>Dev</router-link>
        <router-link to="/marketing"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-clipboard-data-fill" viewBox="0 0 16 16">
            <path
              d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
            <path
              d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1ZM10 8a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V8Zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1Zm4-3a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z" />
          </svg>Marketing</router-link>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-heart"
          viewBox="0 0 16 16">
          <path
            d="M9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276Z" />
        </svg>
      </nav>
    </div>

  </div>
</template>

<script>
export default {
  name: 'MyNavbar',
}
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
  background: #010916;
  z-index: 9999;

  svg {
    height: 20px;
    width: 20px;
    margin-left: 5px;

    path {
      fill: #fff;

    }
  }

  a {
    color: #fff;
    margin: 0px 5px;
    text-decoration: none;

    &.router-link-exact-active {
      color: #EA334B !important;
      transition: 0.2s linear;

      svg {
        path {
          fill: #EA334B !important;
          transition: 0.2s linear;
        }
      }
    }

    @media screen and (max-width:992px) {
      font-size: 0.7rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: uppercase;

      svg {
        display: inherit;
        height: 25px;
        width: 25px;
        margin-left: 0px;
        margin-bottom: 4px;
      }
    }
  }

  svg {
    display: none;
  }



  .mobile {
    display: none;

    svg {
      height: 30px;
      width: 30px;
      margin-left: 0px;

      path {
        fill: #fff;

      }
    }

    @media screen and (max-width:992px) {
      display: inherit;
    }
  }

  nav {
    display: flex;
    align-items: center;

    .bi-person-heart {
      display: inherit;
    }

    @media screen and (max-width:992px) {

      padding: 35px 10px;
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      background: #010916;
      box-shadow: -2px -2px 6px rgb(0 0 0 / 30%);
      display: flex;
      justify-content: center;

      a {
        font-size: 0.7rem;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;

        svg {
          display: inherit;
          margin-bottom: 8px;
          height: 25px;
          width: 25px;
        }
      }

      .bi-person-heart {
        display: none;
      }
    }
  }

  img {
    height: 40px;
  }

  @media screen and (max-width:992px) {
    position: inherit;
    padding: 20px 20px;
  }
}
</style>

