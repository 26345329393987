import { createRouter, createWebHistory } from 'vue-router'
import LeConcept from '../views/First/LeConcept.vue'
import DesignCours from '../views/First/DesignCours.vue'
import PrograCours from '../views/First/PrograCours.vue'
import MarketingCours from '../views/First/MarketingCours.vue'
import DetailsCours from '../views/Second/DetailsCours.vue'
import SousCategorie from '../views/Second/SousCategorie.vue'

const routes = [
  {
    path: '/',
    name: 'le-concept',
    component: LeConcept
  },
  {
    path: '/design',
    name: 'Design',
    component: DesignCours
  },
  {
    path: '/design:',
    name: 'Design',
    component: DesignCours
  },
  {
    path: '/programmation',
    name: 'Programmation',
    component: PrograCours
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: MarketingCours
  },
  {
    path: '/cours/:name',
    name: 'cours',
    component: DetailsCours
  },
  {
    path: '/sous-categorie/:name',
    name: 'Sous-categorie',
    component: SousCategorie
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
