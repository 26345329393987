<template>
  <MyNavbar />
  <router-view />
</template>


<script>
// @ is an alias to /src
import MyNavbar from '@/components/MyNavbar.vue'

export default {
  name: 'app',
  components: {
    MyNavbar,
  }
}
</script>



<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Charis+SIL:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

html {
  background: #010916;
}

#app {

  font-family: 'Poppins', sans-serif;
  text-align: center;
  color: #fff;
  font-weight: 300;
  background: #010916;;
  @media screen and (max-width:992px) {
    padding-bottom: 140px;
  }
  h1, p, h2 {
    color: #fff;
  }
  h1 {
    font-family: 'Charis SIL', serif;
  }

  .primary-color {
    color:#EA334B;
  }

  .btn-primary {
    background:#EA334B;
    border: 1.5px solid #EA334B;
    padding: 10px 30px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    display: inline-block;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.3);
  }
  .btn-secondary {
    background:none;
    border: 1.5px solid #EA334B;
    padding: 10px 30px;
    text-transform: uppercase;
    text-decoration: none;
    color: #EA334B;
    display: inline-block;
    box-shadow: 0px 0px 6px rgba(0,0,0,0.3);
  }
  .image-large {
    width: 100%;
    height: 175px;
    object-fit: cover;
  }
  strong {
    font-weight: 600;
  }
  pre {
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    color: #676767;
}

}
</style>
