<template>
    <div class="coursContainer container">
        <div v-if="error">
            {{ error }}
        </div>
        <div class="row" v-else>
            <h1>Marketing</h1>
            <p class="sous-titre">Les pense-bêtes de la com</p>
            <div class="col-lg-4" v-for="post in filterThemes" :key="post.id">
                 <router-link :to="'/sous-categorie/' + post.toLowerCase()">
                        <div class="coursContainer__container__card">
                            <p class="icone">📂</p>
                            <div class="coursContainer__container__card__titre">
                                <h3 v-html="post"></h3>
                            </div>
                            <div class="call-to-action">
                                <p>Accéder à ce thème</p>
                            </div>
                        </div>
                    </router-link>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['category'],
    data() {
        return {
            posts: [],
        };
    },
    computed: {
        filterThemes: function(){
            return this.uniqueNames(this.filterThemesByCat(this.posts))
        }
    },
    methods: {
        async getData() {
            try {
                let response = await
                    fetch("https://digitopics.fr/admin/wp-json/wp/v2/posts");
                this.posts = await response.json();
                console.log(this.posts);
            } catch (error) {
                console.log(error);
            }
        },
        filterThemesByCat: function (products) { 
            return products.filter((t => t.acf.categorie == this.category))
        },
        uniqueNames: function(products) {
            var Remove_duplicate_Value = [];
            for(var i =0; i < products.length; i++) {
                if(Remove_duplicate_Value.indexOf(products[i].acf.sous_categorie) === -1) {
                    Remove_duplicate_Value.push(products[i].acf.sous_categorie)
                }
            }
        console.log(Remove_duplicate_Value);
        return Remove_duplicate_Value;
        }
    },
    created() {
        this.getData();
    },
};
</script>
